<template>
  <div>
    <EntitiesGallery method="avis" entity="avi" />
  </div>
</template>
<script>
import EntitiesGallery from "@/components/entities/EntitiesGallery";

export default {
  components: {
    EntitiesGallery
  }
}
</script>
